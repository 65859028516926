import {
    Button,
    Flex,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    Text,
    Textarea,
    TextInput,
    Title,
} from '@tremor/react'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useApiV1TicketCreate, useApiV1TicketList } from '../../api/api.gen'
import Modal from '../../components/Layout/modal'
import { toErrorText } from '../../utilities/error'

export default function TicketListPage() {
    const [showModal, setShowModal] = useState(false)
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')

    const navigate = useNavigate()
    const {
        response: ticketList,
        isLoading: ticketListLoading,
        isExecuted: ticketListExecuted,
        sendNow: refresh,
    } = useApiV1TicketList()

    const {
        response: createTicketResponse,
        isLoading: createTicketLoading,
        isExecuted: createTicketExecuted,
        sendNowWithParams: createTicket,
        error: createError,
    } = useApiV1TicketCreate({}, {}, {}, false)

    useEffect(() => {
        if (createTicketExecuted && !createTicketLoading) {
            setShowModal(false)
            navigate(`/ticket/${createTicketResponse}`)
        }
    }, [createTicketLoading])

    return (
        <Flex
            className="p-4 bg-gray-50 w-auto min-w-[1000px] max-w-fit mt-4 rounded-md"
            flexDirection="col"
            justifyContent="start"
            alignItems="start"
        >
            <Flex flexDirection="row" justifyContent="between">
                <Title className="text-gray-800">تیکت‌ها</Title>
                <Flex className="w-fit space-x-2">
                    <Button
                        icon={ArrowPathIcon}
                        variant="secondary"
                        className="ml-2"
                        disabled={ticketListExecuted && ticketListLoading}
                        onClick={() => refresh()}
                    />
                    <Button
                        variant="secondary"
                        onClick={() => setShowModal(true)}
                    >
                        تیکت جدید
                    </Button>
                </Flex>
            </Flex>
            <Table className="w-full">
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>ردیف</TableHeaderCell>
                        <TableHeaderCell>تاریخ</TableHeaderCell>
                        <TableHeaderCell>عنوان</TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ticketList
                        ?.sort(
                            (a, b) =>
                                dayjs(b.created_at).unix() -
                                dayjs(a.created_at).unix()
                        )
                        .map((v) => (
                            <TableRow
                                className="cursor-pointer hover:bg-gray-100"
                                onClick={() => navigate(`/ticket/${v.id}`)}
                            >
                                <TableCell>{(v.id || 0) + 10000}</TableCell>
                                <TableCell>
                                    {dayjs(v.created_at).format(
                                        'YYYY-MM-DD HH:mm:ss'
                                    )}
                                </TableCell>
                                <TableCell>{v.title}</TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>

            <Modal
                title="تیکت جدید"
                show={showModal}
                onCloseClicked={() => setShowModal(false)}
            >
                <Flex
                    flexDirection="col"
                    justifyContent="start"
                    alignItems="start"
                    className="w-96"
                >
                    <Text className="mt-4 mb-1">عنوان:</Text>
                    <TextInput
                        value={title}
                        onValueChange={setTitle}
                        error={title.length === 0}
                        errorMessage="مشخص کردن عنوان الزامی است"
                    />

                    <Text className="mt-4 mb-1">متن:</Text>
                    <Textarea
                        value={content}
                        onValueChange={setContent}
                        error={content.length === 0}
                        errorMessage="پر کردن متن الزامی است"
                        className="h-40"
                    />

                    <div className="mt-6">{toErrorText(createError)}</div>
                    <Button
                        className=""
                        loading={createTicketExecuted && createTicketLoading}
                        disabled={createTicketExecuted && createTicketLoading}
                        onClick={() => {
                            createTicket(
                                {
                                    id: 0,
                                    newTicket: true,
                                    title,
                                    content,
                                },
                                {},
                                {}
                            )
                        }}
                    >
                        ساخت
                    </Button>
                </Flex>
            </Modal>
        </Flex>
    )
}
