import { atom, useAtom } from 'jotai'
import { useEffect, useRef } from 'react'
import { LLMHive } from '../sdk'
import { EntitiesChatItem, EntitiesMessageItem } from '../api/api'

export interface IVM {
    version: number
}

const chatViewModelAtom = atom<IVM>({ version: 0 })
export const useDBChatList = () => {
    const chatsRef = useRef<EntitiesChatItem[]>([])
    const [v, setV] = useAtom(chatViewModelAtom)

    useEffect(() => {
        LLMHive.db.listChats().then((i) => {
            chatsRef.current = i
            setV({ version: v.version + 1 })
        })

        return LLMHive.db.subscribeToChanges(() => {
            LLMHive.db.listChats().then((i) => {
                chatsRef.current = i
                setV({ version: v.version + 1 })
            })
        })
    }, [])

    const chats = chatsRef.current || []
    const setChat = (ci: EntitiesChatItem) => {
        LLMHive.db.addOrUpdateChat(ci).then(() => {
            LLMHive.db.listChats().then((i) => {
                chatsRef.current = i
                setV({ version: v.version + 1 })
            })
        })
    }

    return { chats, setChat }
}

const chatMessagesViewModelAtom = atom<IVM>({ version: 0 })
export const useDBChatMessageList = (chatID: string) => {
    const chatMessagesRef = useRef<EntitiesMessageItem[]>([])
    const [v, setV] = useAtom(chatMessagesViewModelAtom)

    useEffect(() => {
        LLMHive.db.listMessagesInChat(chatID).then((i) => {
            chatMessagesRef.current = i
            setV({ version: v.version + 1 })
        })

        return LLMHive.db.subscribeToChanges(() => {
            LLMHive.db.listMessagesInChat(chatID).then((i) => {
                chatMessagesRef.current = i
                setV({ version: v.version + 1 })
            })
        })
    }, [chatID])

    const chatMessages = chatMessagesRef.current || []
    const setChatMessages = (ci: EntitiesMessageItem[]) => {
        LLMHive.db.setChatMessages(chatID, ci).then(() => {
            chatMessagesRef.current = ci
            setV({ version: v.version + 1 })
        })
    }

    return { chatMessages, setChatMessages }
}
