import { Flex, Text } from '@tremor/react'
import { atom, useAtom } from 'jotai'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

interface INotification {
    text: string
    type: 'alert' | 'success'
    createdAt: dayjs.Dayjs
}

export const notificationAtom = atom<INotification[]>([])

const notifBgColor = (n: INotification) => {
    switch (n.type) {
        case 'alert':
            return 'bg-red-600'
        case 'success':
            return 'bg-green-600'
        default:
            return ''
    }
}

export default function Notification() {
    const [notifs, setNotifs] = useAtom(notificationAtom)
    const [, setRefresh] = useState(false) // state to trigger re-render

    useEffect(() => {
        const interval = setInterval(() => {
            setRefresh((prev) => !prev) // toggle state to trigger re-render
        }, 250)

        return () => clearInterval(interval) // cleanup on unmount
    }, [])

    const opac = (n: INotification) => {
        if (n.createdAt.add(250, 'millisecond').isAfter()) {
            return 'opacity-0 top-10'
        }
        if (n.createdAt.add(5.0, 'seconds').isBefore()) {
            return 'opacity-0 top-10'
        }
        return 'opacity-100 top-0'
    }
    return (
        <Flex
            flexDirection="col"
            justifyContent="end"
            className="fixed bottom-10 left-10 w-fit h-fit space-y-2 transition-all duration-150 ease-in-out"
        >
            {notifs.map((n) => (
                <Flex
                    className={`relative rounded-md w-fit p-2 ${notifBgColor(
                        n
                    )} transition-all duration-150 ease-in-out ${opac(n)}`}
                >
                    <Text className="!text-lg text-white">{n.text}</Text>
                </Flex>
            ))}
        </Flex>
    )
}
