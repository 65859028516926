import { Text } from '@tremor/react'
import { setAuthHeader } from '../../api/ApiConfig'

function clearAllIndexedDB() {
    // Check if `indexedDB` is supported
    if (!window.indexedDB) {
        console.error(
            "Your browser doesn't support a stable version of IndexedDB."
        )
        return
    }

    // Use the `databases()` method to get the list of databases (async)
    if ('databases' in indexedDB) {
        indexedDB
            .databases()
            .then((databases) => {
                databases.forEach((dbInfo) => {
                    if (dbInfo.name) {
                        // Attempt to delete each database
                        const deleteRequest = indexedDB.deleteDatabase(
                            dbInfo.name
                        )

                        deleteRequest.onsuccess = () => {
                            console.log(
                                `Database ${dbInfo.name} successfully deleted.`
                            )
                        }

                        deleteRequest.onerror = () => {
                            console.error(
                                `Failed to delete database ${dbInfo.name}.`
                            )
                        }

                        deleteRequest.onblocked = () => {
                            console.warn(
                                `Database deletion blocked for ${dbInfo.name}.`
                            )
                        }
                    }
                })
            })
            .catch((error) => {
                console.error('Unable to retrieve database list:', error)
            })
    } else {
        console.warn(
            'IndexedDB `databases()` method is not supported in this browser.'
        )

        // Fallback: manually specify database names if `databases()` is unavailable
        const databaseNames = ['db1', 'db2'] // replace with known database names

        databaseNames.forEach((dbName) => {
            const deleteRequest = indexedDB.deleteDatabase(dbName)

            deleteRequest.onsuccess = () => {
                console.log(`Database ${dbName} successfully deleted.`)
            }

            deleteRequest.onerror = () => {
                console.error(`Failed to delete database ${dbName}.`)
            }

            deleteRequest.onblocked = () => {
                console.warn(`Database deletion blocked for ${dbName}.`)
            }
        })
    }
}
export default function LogoutPage() {
    setAuthHeader('')
    localStorage.clear()
    clearAllIndexedDB()
    window.location.href = '/'

    return <Text>Logging out</Text>
}
