import { PaperClipIcon, XMarkIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { useAtom } from 'jotai'
import { Flex, ProgressCircle, Text } from '@tremor/react'
import { AxiosProgressEvent } from 'axios'
import Spinner from '../../components/Spinner'
import { useApiV1UserFilesUploadUpdate } from '../../api/api.gen'
import { notificationAtom } from '../../components/Layout/notification'
import { apiHostname } from '../../api/ApiConfig'
import { toErrorString } from '../../utilities/error'

export interface IAttachment {
    url?: string
    localUrl?: string
    file: File
    progress?: number

    removeIt?: () => void
}

export function Attachment({
    url,
    localUrl,
    progress,
    file,
    removeIt,
}: IAttachment) {
    if (url === undefined) {
        return (
            <ProgressCircle
                value={(progress || 0) * 100}
                size="xl"
                color="slate"
                showAnimation
                radius={30}
                className="p-1"
            >
                <Text>{((progress || 0) * 100).toFixed(0)}%</Text>
            </ProgressCircle>
        )
    }
    if (file.type.includes('image')) {
        return (
            <Flex justifyContent="center" className="relative w-fit p-2 ">
                <img
                    className="w-32"
                    src={apiHostname() + url}
                    alt="attachment"
                />
                <XMarkIcon
                    className="top-1 right-0 absolute w-4 border rounded-full bg-slate-200 border-slate-500 cursor-pointer"
                    onClick={removeIt}
                />
            </Flex>
        )
    }
    return (
        <Flex
            justifyContent="center"
            className="relative w-fit p-2 rounded-full bg-slate-200 border border-slate-500"
        >
            <Text>{file.name}</Text>
            <XMarkIcon
                className="-top-1 -right-1 absolute w-4 border rounded-full bg-slate-200 border-slate-500 cursor-pointer"
                onClick={removeIt}
            />
        </Flex>
    )
}

interface IAttachProps {
    attachments: IAttachment[]
    setAttachments: (v: React.SetStateAction<IAttachment[]>) => void
}

export function AttachButton({ attachments, setAttachments }: IAttachProps) {
    const [notifs, setNotifs] = useAtom(notificationAtom)
    const [uploadUpdate, setUploadUpdate] = useState<AxiosProgressEvent>()
    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const {
        response: uploadResponse,
        isLoading: uploadLoading,
        isExecuted: uploadExecuted,
        sendNowWithParams: uploadFile,
        error: uploadError,
    } = useApiV1UserFilesUploadUpdate(
        { filename: '', filetype: '' },
        { file: new File([], '') },
        {},
        false
    )

    useEffect(() => {
        if (uploadUpdate !== undefined) {
            setAttachments(
                attachments.map((v) => {
                    if (v.localUrl === fileInputRef.current?.value) {
                        return {
                            file: v.file,
                            localUrl: v.localUrl,
                            progress: uploadUpdate.progress,
                        }
                    }
                    return v
                })
            )
        }
    }, [uploadUpdate])

    useEffect(() => {
        if (uploadExecuted && !uploadLoading) {
            const errMsg = toErrorString(uploadError)
            if (errMsg.length > 0) {
                setNotifs([
                    ...notifs,
                    {
                        text: errMsg,
                        type: 'alert',
                        createdAt: dayjs(),
                    },
                ])
                setAttachments(
                    attachments.filter(
                        (v) => v.localUrl !== fileInputRef.current?.value
                    )
                )
            } else {
                const newAttachments = attachments.map((v) => {
                    if (v.localUrl === fileInputRef.current?.value) {
                        return {
                            file: v.file,
                            localUrl: v.localUrl,
                            url: uploadResponse,
                        }
                    }
                    return v
                })
                setAttachments(newAttachments)
            }

            if (
                fileInputRef.current !== undefined &&
                fileInputRef.current !== null
            ) {
                fileInputRef.current.value = ''
            }
        }
    }, [uploadLoading])

    // Function to handle file change event and read the file content
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null) {
            for (let i = 0; i < event.target.files.length; i += 1) {
                const file = event.target.files.item(i)
                if (file) {
                    const reader = new FileReader()

                    // Callback when the file is successfully read
                    reader.onload = (e) => {
                        const fileContent = e.target?.result
                        if (fileContent !== undefined && fileContent !== null) {
                            setAttachments(() => [
                                ...attachments,
                                {
                                    file,
                                    localUrl: fileInputRef.current?.value,
                                },
                            ])
                            uploadFile(
                                { filename: file.name, filetype: file.type },
                                { file: new File([fileContent], file.name) },
                                {
                                    onUploadProgress: (ev) => {
                                        setUploadUpdate(ev)
                                    },
                                }
                            )
                        }
                    }

                    // Callback in case of error while reading
                    reader.onerror = (e) => {
                        setNotifs([
                            ...notifs,
                            {
                                text: String(e),
                                type: 'alert',
                                createdAt: dayjs(),
                            },
                        ])
                    }

                    // Read the file as text
                    reader.readAsArrayBuffer(file)
                }
            }
        }
    }
    const handleAttachClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click()
        }
    }

    return (
        <>
            <input
                key="input_attachment"
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
            {uploadExecuted && uploadLoading ? (
                <span className="relative flex w-10 h-10">
                    <Spinner />
                </span>
            ) : (
                <PaperClipIcon
                    onClick={handleAttachClick}
                    className="w-10 h-10 cursor-pointer p-2"
                />
            )}
        </>
    )
}
