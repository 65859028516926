import { Divider, Flex, Title } from '@tremor/react'
import { useEffect, useState } from 'react'

interface IModal {
    children: any
    show: boolean
    title: string
    onCloseClicked: () => void
}

export default function Modal({
    children,
    title,
    show,
    onCloseClicked,
}: IModal) {
    const [isVisible, setIsVisible] = useState(show)

    useEffect(() => {
        if (show) {
            setIsVisible(true)
            return () => 0
        }
        const timeout = setTimeout(() => setIsVisible(false), 150)
        return () => clearTimeout(timeout)
    }, [show])

    return (
        <Flex
            flexDirection="col"
            justifyContent="start"
            alignItems="end"
            className={`fixed top-0 left-0 w-screen h-screen bg-gray-900/50 z-50 transition-opacity duration-150 ease-linear ${
                show ? 'opacity-100 visible' : 'opacity-0'
            } ${isVisible ? 'visible' : 'invisible'}`}
            onClick={onCloseClicked}
        >
            <Flex
                flexDirection="row"
                alignItems="start"
                justifyContent="start"
                className={`w-fit h-screen bg-white border-l border-gray-300 transition-all ${
                    show ? 'relative left-0' : 'relative -left-96'
                }`}
                onClick={(e) => {
                    e.stopPropagation()
                }}
            >
                <Flex
                    flexDirection="col"
                    justifyContent="start"
                    alignItems="start"
                >
                    <Title className="p-4">{title}</Title>
                    <Divider className="m-0 p-0" />
                    <Flex
                        flexDirection="col"
                        justifyContent="start"
                        alignItems="start"
                        className="px-8"
                    >
                        {children}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}
