/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Card, Flex, Text } from '@tremor/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { useAuth } from '../../utilities/auth'

export function Profile() {
    const { user } = useAuth()
    const navigate = useNavigate()
    const [showMenu, setShowMenu] = useState(false)

    const items = [
        {
            icon: ArrowTopRightOnSquareIcon,
            text: 'خروج',
            link: '/logout',
        },
    ]

    return (
        <Flex flexDirection="row" className="relative w-fit">
            <div
                onClick={() => setShowMenu(true)}
                className="w-8 h-8 cursor-pointer rounded-full flex items-center justify-center text-white text-sm font-normal bg-gray-500"
            >
                {user.name?.substring(0, 2)?.toUpperCase()}
            </div>

            {showMenu && (
                <>
                    <div
                        className="fixed top-0 left-0 w-full h-full bg-gray-100/0 z-40"
                        onClick={() => setShowMenu(false)}
                    />
                    <Flex className="absolute top-9 left-1 w-40 bg-gray-100/0 z-50">
                        <Card className="py-1 px-1">
                            <Flex
                                flexDirection="col"
                                alignItems="start"
                                className="w-full space-y-0"
                            >
                                {items.map((m) => (
                                    <Flex
                                        flexDirection="row"
                                        justifyContent="start"
                                        className="hover:bg-gray-100 p-2 cursor-pointer"
                                        onClick={() => {
                                            navigate(m.link)
                                            setShowMenu(false)
                                        }}
                                    >
                                        <m.icon className="pt-1 w-5 h-5 ml-1" />
                                        <Text className="!text-sm">
                                            {m.text}
                                        </Text>
                                    </Flex>
                                ))}
                            </Flex>
                        </Card>
                    </Flex>
                </>
            )}
        </Flex>
    )
}
