/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Flex, Text, Textarea } from '@tremor/react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PhoneIcon } from '@heroicons/react/24/outline'
import { ArrowUpCircleIcon } from '@heroicons/react/24/solid'
import { v4 as uuidv4 } from 'uuid'
import { models, ModelSelector } from './modelselector'
import { Profile } from './profile'
import { isRTL } from '../../utilities/rtl'
import { ChatItem } from './chatItem'
import { LLMHive } from '../../sdk'
import { useDBChatMessageList } from '../../db/viewmodel'
import { Microphone } from './microphone'
import { AttachButton, Attachment, IAttachment } from './attach'

export default function HomePage() {
    const navigate = useNavigate()
    const scrollRef = useRef<HTMLDivElement>(null)
    const { threadId } = useParams()
    const [model, setModel] = useState('gpt-4o')
    const [modelTitle, setModelTitle] = useState('ChatGPT 4o')
    const [chatInput, setChatInput] = useState('')
    const [attachments, setAttachments] = useState<IAttachment[]>([])
    const [stickToBottom, setStickToBottom] = useState(true)

    const attachmentLoading =
        attachments.filter((v) => v.url === undefined).length > 0
    useEffect(() => {
        if (threadId !== undefined && threadId !== '') {
            LLMHive.sendMessage({
                subscribeToChatMessages: { chatID: threadId },
            })
        }
    }, [threadId])

    const onModelChanged = (newModel: string) => {
        setModel(newModel)
        setModelTitle(
            models.find((v) => v.value === newModel)?.name || 'unknown'
        )
    }

    const sendToChat = (text: string) => {
        if (text.trim().length === 0) {
            return
        }

        const msgID = uuidv4()
        LLMHive.sendMessage({
            sendMessage: {
                ID: msgID,
                chatID: threadId,
                content: text.trim(),
                attachments: attachments.map((v) => {
                    return {
                        url: v.url,
                        filename: v.file.name,
                        fileType: v.file.type,
                    }
                }),
                model,
            },
        })
        setAttachments([])
    }

    const sendChatInput = () => {
        if (attachmentLoading || chatInput.length === 0) {
            return
        }
        setStickToBottom(true)
        sendToChat(chatInput)
        setChatInput('')
    }

    const { chatMessages: messages } = useDBChatMessageList(threadId || '')
    const assistantItemId = messages
        ?.map((v, idx) => (v.role === 'assistant' ? idx : -1))
        .filter((v) => v !== -1)
    const lastAssistantItemId =
        assistantItemId?.at(assistantItemId.length - 1) || -1

    const isAtBottom = () => {
        if (scrollRef.current === null) {
            return false
        }
        const { scrollHeight, scrollTop, clientHeight } = scrollRef.current
        return scrollHeight === scrollTop + clientHeight
    }

    const handleScroll = () => {
        if (isAtBottom()) {
            setStickToBottom(true)
        } else {
            setStickToBottom(false)
        }
    }

    const scrollToBottom = () => {
        if (scrollRef.current !== null) {
            scrollRef.current.scrollTop = scrollRef.current?.scrollHeight
        }
    }
    useEffect(() => {
        const ref = scrollRef.current
        if (ref === null) {
            return () => 0
        }

        ref.addEventListener('scroll', handleScroll)

        // Cleanup event listener on component unmount
        return () => {
            ref.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        if (stickToBottom) {
            scrollToBottom()
        }
    }, [messages])

    return (
        <Flex flexDirection="col" className="h-full bg-white py-2">
            <Flex flexDirection="row" className="mb-4 px-4">
                <ModelSelector
                    model={model}
                    modelTitle={modelTitle}
                    onModelChange={onModelChanged}
                />
                <Profile />
            </Flex>
            <Flex
                ref={scrollRef}
                flexDirection="col"
                justifyContent="start"
                className="h-full overflow-y-auto px-4 space-y-2"
            >
                {messages?.map((c, idx) => (
                    <ChatItem
                        message={c}
                        isLastItem={lastAssistantItemId === idx}
                    />
                ))}
            </Flex>
            <Flex flexDirection="col" className="px-4 pt-2 mt-2 bg-slate-100">
                {attachments.length > 0 && (
                    <Flex
                        flexDirection="row"
                        justifyContent="start"
                        className="my-1"
                    >
                        {attachments.map((v) => {
                            return (
                                <Attachment
                                    {...v}
                                    removeIt={() => {
                                        setAttachments(
                                            attachments.filter(
                                                (i) => i.url !== v.url
                                            )
                                        )
                                    }}
                                />
                            )
                        })}
                    </Flex>
                )}

                <Flex
                    flexDirection="row"
                    className="bg-slate-200 rounded-full p-1"
                >
                    <AttachButton
                        attachments={attachments}
                        setAttachments={setAttachments}
                    />
                    <Microphone sendToChat={sendToChat} />
                    <PhoneIcon
                        onClick={() => navigate(`/call/${model}/${threadId}`)}
                        className="w-10 h-10 cursor-pointer p-2"
                    />
                    <Textarea
                        dir={
                            isRTL(chatInput) || chatInput === '' ? 'rtl' : 'ltr'
                        }
                        placeholder="پیام برای هوش مصنوعی بفرست"
                        className="bg-transparent max-h-36 min-h-10 border-0 ring-0 shadow-none focus:ring-0 hover:bg-transparent"
                        value={chatInput}
                        rows={1}
                        onValueChange={setChatInput}
                        onKeyDown={(e) =>
                            e.ctrlKey && e.key === 'Enter' && sendChatInput()
                        }
                    />
                    <ArrowUpCircleIcon
                        className={`w-10 h-10 ${
                            chatInput.length > 0 && !attachmentLoading
                                ? 'text-black cursor-pointer'
                                : 'text-gray-300'
                        }`}
                        onClick={() => sendChatInput()}
                    />
                </Flex>
                <Text>
                    هوش مصنوعی میتونه اشتباه کنه. اطلاعات مهم رو خودتون چک کنین.
                </Text>
            </Flex>
        </Flex>
    )
}
