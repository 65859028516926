import { Button, Flex, Text, Textarea, Title } from '@tremor/react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { useApiV1TicketCreate, useApiV1TicketDetail } from '../../api/api.gen'
import Spinner from '../../components/Spinner'

export default function TicketPage() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [message, setMessage] = useState('')
    const {
        response: ticketList,
        isLoading: ticketListLoading,
        isExecuted: ticketListExecuted,
        sendNow: refresh,
    } = useApiV1TicketDetail(id || '')

    const {
        isLoading: respondLoading,
        isExecuted: respondExecuted,
        sendNowWithParams: respond,
    } = useApiV1TicketCreate({}, {}, {}, false)

    useEffect(() => {
        if (respondExecuted && !respondLoading) {
            refresh()
        }
    }, [respondLoading])

    return (
        <Flex
            className="p-4 bg-gray-50 w-auto min-w-[1000px] max-w-fit mt-4 rounded-md space-y-2"
            flexDirection="col"
            justifyContent="start"
            alignItems="start"
        >
            <Flex flexDirection="row" justifyContent="between" className="mb-4">
                <Title className="text-gray-800">تیکت</Title>
                <Button
                    variant="secondary"
                    onClick={() => navigate('/tickets')}
                >
                    برگشت
                </Button>
            </Flex>
            {ticketListLoading && <Spinner />}
            {ticketList?.map((v) => (
                <Flex flexDirection="row" className="w-full">
                    <Flex
                        flexDirection="col"
                        justifyContent="start"
                        alignItems="start"
                        className={`w-full p-2 rounded-md ${
                            v.receiving === true
                                ? 'bg-sky-100'
                                : 'bg-orange-100'
                        }`}
                    >
                        <Flex flexDirection="row" justifyContent="between">
                            <Text className="text-black">
                                {v.receiving === true ? 'Support Team' : 'You'}
                            </Text>
                            <Text className="text-black">
                                {dayjs(v.created_at).format(
                                    'YYYY-MM-DD HH:mm:ss'
                                )}
                            </Text>
                        </Flex>
                        <p className="mt-4 text-black">{v.message_content}</p>
                    </Flex>
                </Flex>
            ))}
            <Text className="mt-8 mb-2">پاسخ</Text>
            <Textarea
                value={message}
                onValueChange={setMessage}
                className="h-36"
            />
            <Flex flexDirection="row" justifyContent="end" className="mt-2">
                <Button
                    variant="secondary"
                    loading={respondExecuted && respondLoading}
                    disabled={respondExecuted && respondLoading}
                    onClick={() =>
                        respond(
                            {
                                id: parseInt(id || '', 10),
                                content: message,
                                newTicket: false,
                                title: '',
                            },
                            {},
                            {}
                        )
                    }
                >
                    ارسال پاسخ
                </Button>
            </Flex>
        </Flex>
    )
}
