import { Button, Flex, Text } from '@tremor/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Bars3Icon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import Modal from './modal'
import { useApiV1MeList } from '../../api/api.gen'
import Spinner from '../Spinner'
import { EntitiesChatItem } from '../../api/api'
import { LLMHive } from '../../sdk'
import { useDBChatList } from '../../db/viewmodel'

interface GroupedItems {
    group: string
    items: EntitiesChatItem[]
}

function getGroup(itemDate: dayjs.Dayjs): string {
    if (itemDate.isToday()) return 'Today'
    if (itemDate.isYesterday()) return 'Yesterday'
    if (itemDate.isAfter(dayjs().subtract(7, 'day'))) return 'Last week'
    if (itemDate.isAfter(dayjs().subtract(1, 'month'))) return 'Last month'
    return itemDate.year().toString()
}

function groupByTime(items: EntitiesChatItem[]): GroupedItems[] {
    const groupedMap: Record<string, EntitiesChatItem[]> = {}

    items.forEach((item) => {
        const itemDate = dayjs(item.createdAt)
        const group = getGroup(itemDate)

        if (!groupedMap[group]) {
            groupedMap[group] = []
        }
        groupedMap[group].push(item)
    })

    // Convert the object into an array of GroupedItems
    return Object.keys(groupedMap)
        .map((group) => {
            let groupSort = 0
            switch (group) {
                case 'Today':
                    groupSort = 9999
                    break
                case 'Yesterday':
                    groupSort = 9998
                    break
                case 'Last week':
                    groupSort = 9997
                    break
                case 'Last month':
                    groupSort = 9996
                    break
                default:
                    groupSort = parseInt(group, 10)
                    break
            }

            return {
                group,
                groupSort,
                items: groupedMap[group].sort(
                    (a, b) =>
                        dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix()
                ),
            }
        })
        .sort((a, b) => b.groupSort - a.groupSort)
}

export default function Sidebar() {
    const navigate = useNavigate()
    const sidebarRef = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState(window.innerWidth >= 768)
    const [showModal, setShowModal] = useState(false)
    const { chats } = useDBChatList()

    const {
        response: me,
        isLoading: meLoading,
        isExecuted: meExecuted,
    } = useApiV1MeList()

    const grouped = groupByTime(chats)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setOpen(true)
            } else {
                setOpen(false)
            }
        }

        LLMHive.sendMessage({ subscribeToChats: {} })
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                sidebarRef.current &&
                !sidebarRef.current.contains(event.target as Node) &&
                window.innerWidth < 768
            ) {
                setOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <Flex
            ref={sidebarRef}
            flexDirection="col"
            justifyContent="between"
            className={`w-fit h-full ${
                open ? 'min-w-60 bg-gray-100' : 'min-w-0'
            } ${window.innerWidth < 768 && open ? 'absolute z-50' : 'flex'}`}
        >
            <Flex
                flexDirection={open ? 'row' : 'col'}
                justifyContent="between"
                className={`p-3 ${!open && 'space-y-2'}`}
            >
                <Bars3Icon
                    onClick={() => setOpen(!open)}
                    className="w-9 h-9 p-2 rounded-md cursor-pointer text-gray-500 hover:bg-gray-200"
                />
                <PencilSquareIcon
                    onClick={() => navigate('/')}
                    className="w-9 h-9 p-2 rounded-md cursor-pointer text-gray-500 hover:bg-gray-200"
                />
            </Flex>
            {open && (
                <>
                    <Flex
                        flexDirection="col"
                        justifyContent="start"
                        className="p-3 h-full overflow-y-auto"
                    >
                        {grouped.map((g) => (
                            <Flex flexDirection="col">
                                <Text className="font-bold p-2">{g.group}</Text>
                                {g.items.map((v) => (
                                    <Flex
                                        className="w-full rounded-md p-2 cursor-pointer hover:bg-gray-200"
                                        onClick={() => navigate(`/c/${v.id}`)}
                                    >
                                        <Text>{v.title}</Text>
                                    </Flex>
                                ))}
                            </Flex>
                        ))}
                    </Flex>
                    <Flex
                        flexDirection="col"
                        justifyContent="start"
                        alignItems="start"
                        className="p-3"
                    >
                        <Flex className="mb-2">
                            <Text>موجودی:</Text>
                            <Flex flexDirection="row" className="w-fit">
                                <Text
                                    className={`text-black font-bold ${
                                        (me?.balance || 0) < 1000
                                            ? 'text-red-600'
                                            : ''
                                    }`}
                                >
                                    {meExecuted && meLoading ? (
                                        <Spinner />
                                    ) : (
                                        me?.balance?.toFixed(0)
                                    )}
                                </Text>
                                <Text className="text-gray-700 mr-1">
                                    تومان
                                </Text>
                            </Flex>
                        </Flex>
                        <Button
                            variant="secondary"
                            size="xs"
                            className="w-full"
                            onClick={() => setShowModal(true)}
                        >
                            افزایش موجودی
                        </Button>
                        <Button
                            variant="secondary"
                            size="xs"
                            className="w-full mt-2"
                            onClick={() => navigate('/tickets')}
                        >
                            پشتیبانی
                        </Button>
                    </Flex>
                </>
            )}
            <Modal
                show={showModal}
                onCloseClicked={() => setShowModal(false)}
                title="افزایش موجودی"
            >
                <Text className="!text-lg text-black mt-2">
                    لطفا جهت افزایش موجودی حساب کاربری خود، مبلغ مورد نیاز را به
                    شماره کارت زیر انتقال داده و تاریخ و ساعت واریزی، مبلغ و
                    شماره پیگیری را در تیکت برای پشتیبانی ارسال کنید
                </Text>
                <Text className="!text-xl text-black text-center w-full mt-4">
                    6037-9972-6145-1513
                </Text>
            </Modal>
        </Flex>
    )
}
