import { MicrophoneIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import Spinner from '../../components/Spinner'
import { useApiV1SttCreate } from '../../api/api.gen'

interface IMicrophoneProps {
    sendToChat: (message: string) => void
}

export function Microphone({ sendToChat }: IMicrophoneProps) {
    const [recording, setRecording] = useState(false)
    const {
        response: sstResponse,
        isLoading: sstLoading,
        isExecuted: sstExecuted,
        sendNowWithParams: sst,
    } = useApiV1SttCreate({ file: new File([], '') }, {}, false)

    useEffect(() => {
        if (sstExecuted && !sstLoading) {
            sendToChat(sstResponse || '')
        }
    }, [sstLoading])

    const handleMicrophoneClick = () => {
        // Start recording audio from microphone
        setRecording(true)

        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                const mediaRecorder = new MediaRecorder(stream)
                const audioChunks: BlobPart[] = []

                mediaRecorder.ondataavailable = (event) => {
                    audioChunks.push(event.data)
                }
                let shouldSend = true

                mediaRecorder.onstop = () => {
                    const audioBlob = new Blob(audioChunks, {
                        type: 'audio/wav',
                    })

                    if (shouldSend) {
                        sst(
                            {
                                file: new File(
                                    [audioBlob],
                                    'microphone-audio.wav'
                                ),
                            },
                            {}
                        )
                    }
                }

                mediaRecorder.start()

                const startTime = dayjs()

                const stopRecording = () => {
                    if (dayjs().diff(startTime, 'second') < 1) {
                        shouldSend = false
                    }
                    mediaRecorder.stop()
                    stream.getTracks().forEach((track) => track.stop())
                    setRecording(false)
                }

                document.addEventListener('mouseup', stopRecording, {
                    once: true,
                })
            })
            .catch((error) => {
                setRecording(false)
                console.error('Error accessing microphone:', error)
            })
    }

    if (recording) {
        return (
            <span className="relative flex w-10 h-10">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600 opacity-75" />
                <span className="relative inline-flex rounded-full m-2 w-6 h-6 bg-red-600" />
            </span>
        )
    }

    if (sstExecuted && sstLoading) {
        return (
            <span className="relative flex w-10 h-10">
                <Spinner />
            </span>
        )
    }

    return (
        <MicrophoneIcon
            onMouseDown={handleMicrophoneClick}
            className="w-10 h-10 cursor-pointer p-2"
        />
    )
}
