import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MicrophoneIcon, SpeakerWaveIcon } from '@heroicons/react/24/outline'
import { base64ToUint8Array } from '../../utilities/base64'
import { LLMHive } from '../../sdk'

function blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onloadend = () => {
            const base64String = reader.result as string
            const base64Part = base64String.split(',')[1] // Get the part after the comma
            resolve(base64Part)
        }

        reader.onerror = (error) => {
            reject(error)
        }

        reader.readAsDataURL(blob)
    })
}

const CallAIPage = () => {
    const { model, threadId } = useParams()
    const mediaRecorder = useRef<MediaRecorder | null>(null)
    const audioChunks = useRef<Blob[]>([])
    const [recording, setRecording] = useState<boolean>(false)

    const detectSilence = (
        stream: MediaStream,
        onSilence: () => void,
        silenceDelay = 500,
        interval = 50
    ) => {
        const audioContext = new (window.AudioContext ||
            (window as any).webkitAudioContext)()
        const analyser = audioContext.createAnalyser()
        const microphone = audioContext.createMediaStreamSource(stream)
        const scriptProcessor = audioContext.createScriptProcessor(256, 1, 1)

        analyser.smoothingTimeConstant = 0.8
        analyser.fftSize = 1024

        microphone.connect(analyser)
        analyser.connect(scriptProcessor)
        scriptProcessor.connect(audioContext.destination)

        let silenceStart: number | null = null
        console.log('detectSilence')

        scriptProcessor.onaudioprocess = () => {
            const array = new Uint8Array(analyser.frequencyBinCount)
            analyser.getByteFrequencyData(array)
            const arraySum = array.reduce((a, value) => a + value, 0)
            console.log('onaudioprocess', arraySum)
            if (arraySum < 5000) {
                if (silenceStart === null) {
                    silenceStart = performance.now()
                } else if (performance.now() - silenceStart > silenceDelay) {
                    scriptProcessor.disconnect()
                    microphone.disconnect()
                    onSilence()
                }
            } else {
                silenceStart = null
            }
        }
    }

    const startRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
        })
        mediaRecorder.current = new MediaRecorder(stream, {
            mimeType: 'audio/webm; codecs=opus',
        })

        mediaRecorder.current.addEventListener('dataavailable', (event) => {
            if (event.data.size > 0) {
                console.log('data')
                audioChunks.current.push(event.data)
            }
        })

        mediaRecorder.current.addEventListener('stop', () => {
            console.log('onStop')
            const audioBlob = new Blob(audioChunks.current, {
                type: 'audio/webm',
            })
            audioChunks.current = []

            // Send audioBlob to server over WebSocket
            blobToBase64(audioBlob).then((base64) => {
                console.log('==>', base64)
                LLMHive.sendMessage({
                    callVoice: {
                        chatID: threadId === 'undefined' ? undefined : threadId,
                        model,
                        voiceBytesBase64: base64,
                    },
                })
            })
        })

        console.log('starting recording')
        mediaRecorder.current.start()

        // Implement silence detection
        detectSilence(stream, () => {
            console.log('onDetectSilence')
            if (
                mediaRecorder.current &&
                mediaRecorder.current.state !== 'inactive'
            ) {
                console.log('stopping recording')
                mediaRecorder.current.stop()
                setRecording(false)
            }
            stream.getTracks().forEach((track) => track.stop())
        })

        setRecording(true)
    }

    useEffect(() => {
        LLMHive.callModel = model || ''
        LLMHive.callHandler = (event) => {
            const binaryString = event.voiceBytesBase64 || ''
            const uint8Array = base64ToUint8Array(binaryString)

            // Create a Blob with WAV MIME type
            const blob = new Blob([uint8Array], { type: 'audio/wav' })

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob)

            // Create and play the audio
            const audioRef = new Audio(url)

            audioRef
                .play()
                .catch((error) => console.error('Playback failed:', error))
            // Optional: Revoke the object URL after playback
            audioRef.onended = () => {
                URL.revokeObjectURL(url)
                startRecording()
            }
        }

        const t = setTimeout(() => {
            startRecording()
        }, 1000)

        return () => {
            clearTimeout(t)
            LLMHive.callModel = ''
            LLMHive.callHandler = undefined
        }
    }, [])

    return (
        <div className="h-screen w-full bg-gradient-to-b from-slate-900 to-slate-800 flex flex-col items-center justify-center p-8">
            <div className="relative h-[600px] w-full max-w-md">
                {/* AI Circle */}
                <div
                    className={`absolute top-0 left-1/2 -translate-x-1/2 w-32 h-32
          ${!recording ? 'scale-110 transition-transform duration-300' : ''}`}
                >
                    {/* Outer glow */}
                    <div
                        className={`absolute inset-0 rounded-full bg-blue-500/30 blur-xl transform scale-150
            ${!recording ? 'animate-pulse' : ''}`}
                    />
                    {/* Main circle */}
                    <div
                        className="absolute inset-0 rounded-full bg-gradient-to-br from-blue-400 to-blue-600 
            shadow-lg shadow-blue-500/50 flex items-center justify-center"
                    >
                        {/* Inner rotating circles */}
                        <div
                            className={`w-24 h-24 rounded-full border-4 border-blue-300/30
              ${!recording ? 'animate-spin' : ''}`}
                        >
                            <div
                                className={`w-full h-full rounded-full border-t-4 border-blue-200
                ${!recording ? 'animate-ping' : ''}`}
                            />
                        </div>
                        {/* Icon */}
                        <SpeakerWaveIcon
                            className={`absolute text-blue-100 w-8 h-8
              ${!recording ? 'animate-pulse' : 'opacity-50'}`}
                        />
                    </div>
                </div>

                {/* Connection Line */}
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-1 h-[400px]">
                    <div
                        className="w-full h-full bg-gradient-to-b from-blue-500/20 via-purple-500/20 to-green-500/20
            rounded-full blur-sm"
                    />
                </div>

                {/* User Circle */}
                <div
                    className={`absolute bottom-0 left-1/2 -translate-x-1/2 w-32 h-32
          ${recording ? 'scale-110 transition-transform duration-300' : ''}`}
                >
                    {/* Outer glow */}
                    <div
                        className={`absolute inset-0 rounded-full bg-green-500/30 blur-xl transform scale-150
            ${recording ? 'animate-pulse' : ''}`}
                    />
                    {/* Main circle */}
                    <div
                        className="absolute inset-0 rounded-full bg-gradient-to-br from-green-400 to-green-600
            shadow-lg shadow-green-500/50 flex items-center justify-center"
                    >
                        {/* Inner rotating circles */}
                        <div
                            className={`w-24 h-24 rounded-full border-4 border-green-300/30
              ${recording ? 'animate-spin' : ''}`}
                        >
                            <div
                                className={`w-full h-full rounded-full border-t-4 border-green-200
                ${recording ? 'animate-ping' : ''}`}
                            />
                        </div>
                        {/* Icon */}
                        <MicrophoneIcon
                            className={`absolute text-green-100 w-8 h-8
              ${recording ? 'animate-pulse' : 'opacity-50'}`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CallAIPage
