/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Card, Flex, Text } from '@tremor/react'
import { useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/24/solid'

export const models = [
    {
        name: 'GPT-4o',
        description: 'برای اکثر تسک‌ها عالیه',
        value: 'gpt-4o',
    },
    {
        name: 'GPT-4o mini',
        description: 'سریع‌تر برای تسک‌های روزمره',
        value: 'gpt-4o-mini',
    },
    {
        name: 'o1-preview',
        description: 'از استدلال پیشرفته استفاده می‌کنه',
        value: 'o1-preview',
    },
    {
        name: 'o1-mini',
        description: 'سریع‌تر در استدلال',
        value: 'o1-mini',
    },
    {
        name: 'GPT-4',
        description: 'مدل قدیمی',
        value: 'gpt-4',
    },
]

interface IModelSelector {
    model: string
    modelTitle: string
    onModelChange: (newModel: string) => void
}

export function ModelSelector({
    model,
    modelTitle,
    onModelChange,
}: IModelSelector) {
    const [showMenu, setShowMenu] = useState(false)

    return (
        <Flex flexDirection="row" className="relative w-fit">
            <Flex
                flexDirection="row"
                className="w-fit hover:bg-gray-50 rounded-md p-2 cursor-pointer"
                onClick={() => setShowMenu(!showMenu)}
            >
                <Text className="!text-lg text-gray-500 font-semibold">
                    {modelTitle}
                </Text>
                <ChevronDownIcon className="mr-1 w-4 h-4 text-gray-400" />
            </Flex>

            {showMenu && (
                <>
                    <div
                        className="fixed top-0 left-0 w-full h-full bg-gray-100/0 z-40"
                        onClick={() => setShowMenu(false)}
                    />
                    <Flex className="absolute top-14 right-0 w-96 bg-gray-100/0 z-50">
                        <Card className="py-1 px-1">
                            <Flex
                                flexDirection="col"
                                alignItems="start"
                                className="w-full space-y-0"
                            >
                                <Text className="text-gray-400 p-2">
                                    مدلی که می‌خواید رو انتخاب کنید
                                </Text>

                                {models.map((m) => (
                                    <Flex
                                        flexDirection="row"
                                        justifyContent="between"
                                        className="hover:bg-gray-100 p-2 cursor-pointer"
                                        onClick={() => {
                                            onModelChange(m.value)
                                            setShowMenu(false)
                                        }}
                                    >
                                        <Flex
                                            flexDirection="col"
                                            alignItems="start"
                                        >
                                            <Text className="text-black">
                                                {m.name}
                                            </Text>
                                            <Text className="text-gray-500 !text-xs">
                                                {m.description}
                                            </Text>
                                        </Flex>
                                        {model === m.value && (
                                            <CheckCircleIcon className="w-6 h-6" />
                                        )}
                                    </Flex>
                                ))}
                            </Flex>
                        </Card>
                    </Flex>
                </>
            )}
        </Flex>
    )
}
