/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Flex, Text } from '@tremor/react'
import { useEffect, useRef, useState } from 'react'
import {
    CheckIcon,
    DocumentDuplicateIcon,
    SpeakerWaveIcon,
    StopIcon,
} from '@heroicons/react/24/outline'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { useApiV1TtsCreate } from '../../api/api.gen'
import Spinner from '../../components/Spinner'
import { isRTL } from '../../utilities/rtl'
import { base64ToUint8Array } from '../../utilities/base64'
import { EntitiesMessageItem } from '../../api/api'
import { Attachment } from './attach'
import { apiHostname } from '../../api/ApiConfig'

interface IChatItem {
    message: EntitiesMessageItem
    isLastItem: boolean
}

export function ChatItem({ message, isLastItem }: IChatItem) {
    const audioRef = useRef<HTMLAudioElement>()
    const [copied, setCopied] = useState(false)
    const [mouseIn, setMouseIn] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const { response, isExecuted, isLoading, sendNowWithParams } =
        useApiV1TtsCreate({}, {}, false)

    useEffect(() => {
        if (isExecuted && !isLoading) {
            setIsPlaying(true)
            const binaryString = response || ''

            const uint8Array = base64ToUint8Array(binaryString)

            // Create a Blob with WAV MIME type
            const blob = new Blob([uint8Array], { type: 'audio/wav' })

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob)

            // Create and play the audio
            audioRef.current = new Audio(url)

            audioRef.current
                .play()
                .catch((error) => console.error('Playback failed:', error))

            // Optional: Revoke the object URL after playback
            audioRef.current.onended = () => {
                URL.revokeObjectURL(url)
                setIsPlaying(false)
            }
        }
    }, [isLoading])

    const content: () => string | EntitiesMessageItem[] | undefined = () => {
        return message.content
    }

    const contentString = () => {
        const c = content()
        switch (typeof c) {
            case 'string':
                return c
            case 'undefined':
                return ''
            default:
                return (c as EntitiesMessageItem[])
                    .map((v) => v.content || '')
                    .join('')
        }
    }

    return (
        <Flex
            flexDirection="col"
            className="w-full"
            alignItems={message.role === 'user' ? 'start' : 'end'}
            onMouseEnter={() => setMouseIn(true)}
            onMouseLeave={() => setMouseIn(false)}
        >
            <Flex
                flexDirection="col"
                dir={isRTL(contentString()) ? 'rtl' : 'ltr'}
                className="w-fit"
                alignItems="end"
                justifyContent={message.role === 'user' ? 'start' : 'end'}
            >
                {(message.attachments?.length || 0) > 0 && (
                    <Flex
                        flexDirection="row"
                        justifyContent="start"
                        className="w-fit mb-2 space-x-2"
                    >
                        {message.attachments?.map((v) => {
                            if (v.fileType?.includes('image')) {
                                return (
                                    <img
                                        className="w-32"
                                        src={apiHostname() + v.url}
                                        alt="attachment"
                                    />
                                )
                            }
                            return <Text>{v.filename}</Text>
                        })}
                    </Flex>
                )}
                <Markdown
                    className={`p-2 text-black ${
                        message.role === 'user' ? 'bg-gray-100' : 'bg-sky-50'
                    } rounded-md`}
                    remarkPlugins={[remarkGfm]}
                >
                    {contentString()}
                </Markdown>
            </Flex>
            {message.role === 'assistant' && (
                <Flex
                    flexDirection="row"
                    justifyContent="end"
                    className={`mt-2 ${
                        mouseIn || isLastItem ? '' : 'opacity-0'
                    }`}
                >
                    {copied ? (
                        <CheckIcon className="w-8 h-8 p-2 rounded-full text-gray-600 bg-gray-100 hover:bg-gray-200" />
                    ) : (
                        <DocumentDuplicateIcon
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    message.content || ''
                                )
                                setCopied(true)
                                setTimeout(() => setCopied(false), 1000)
                            }}
                            className="w-8 h-8 p-2 rounded-full cursor-pointer text-gray-600 bg-gray-100 hover:bg-gray-200"
                        />
                    )}

                    {isExecuted && isLoading && (
                        <Spinner className="mr-2 w-8 h-8 py-0.5 pl-2 pr-0 rounded-full cursor-pointer text-gray-600 bg-gray-100 hover:bg-gray-200" />
                    )}

                    {isPlaying && (
                        <StopIcon
                            onClick={() => {
                                audioRef.current?.pause()
                                setIsPlaying(false)
                            }}
                            className="mr-2 w-8 h-8 p-2 rounded-full cursor-pointer text-gray-600 bg-gray-100 hover:bg-gray-200"
                        />
                    )}

                    {!(isExecuted && isLoading) && !isPlaying && (
                        <SpeakerWaveIcon
                            onClick={() => {
                                sendNowWithParams(
                                    {
                                        inputText: message.content,
                                    },
                                    {}
                                )
                            }}
                            className="mr-2 w-8 h-8 p-2 rounded-full cursor-pointer text-gray-600 bg-gray-100 hover:bg-gray-200"
                        />
                    )}
                </Flex>
            )}
        </Flex>
    )
}
