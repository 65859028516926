import { Text } from '@tremor/react'
import { AxiosError } from 'axios'

export const toErrorString = (err: any) => {
    if (err !== undefined && err !== null && err && String(err).length > 0) {
        let errText = String(err)
        console.log(err)
        if (err instanceof AxiosError && err.isAxiosError) {
            if (err.response?.data.message) {
                errText = err.response?.data.message
            } else {
                errText = `${err.code}: ${err.message}, ${err}`
            }
        }
        return errText
    }
    return ''
}

export const toErrorText = (err: any, prefix = '') => {
    if (err !== undefined && err !== null && err && String(err).length > 0) {
        let errText = String(err)
        if (err instanceof AxiosError && err.isAxiosError) {
            if (err.response?.data.message) {
                errText = err.response?.data.message
            } else {
                errText = err.message
            }
        }
        return <Text className="text-red-600">{prefix + errText}</Text>
    }
    return ''
}
