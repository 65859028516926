/* eslint-disable no-await-in-loop */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
import { DBSchema, IDBPDatabase, openDB } from 'idb'
import { EntitiesChatItem, EntitiesMessageItem } from '../api/api'

interface MyDB extends DBSchema {
    chats: { key: string; value: EntitiesChatItem }
    messagesInChat: { key: string; value: EntitiesMessageItem[] }
}

type UnsubscribeFunction = () => void

class IndexedDb {
    private db: IDBPDatabase<MyDB> | undefined

    private objectStoreConnect: Promise<boolean>

    private onChangeCallback: (() => void)[] = []

    constructor() {
        this.objectStoreConnect = this.createObjectStore()
        this.onChangeCallback = []
    }

    private async createObjectStore() {
        try {
            this.db = await openDB<MyDB>('ai', 1, {
                upgrade(db: IDBPDatabase<MyDB>) {
                    if (!db.objectStoreNames.contains('chats')) {
                        db.createObjectStore('chats', {
                            keyPath: 'id',
                        })
                    }
                    if (!db.objectStoreNames.contains('messagesInChat')) {
                        db.createObjectStore('messagesInChat')
                    }
                },
            })
            return true
        } catch (error) {
            return false
        }
    }

    public async listChats() {
        await this.objectStoreConnect
        const tx = this.db?.transaction('chats', 'readonly')
        const store = tx?.objectStore('chats')
        const result = await store?.getAll()
        return result || []
    }

    public async listMessagesInChat(chatID: string) {
        await this.objectStoreConnect
        const tx = this.db?.transaction('messagesInChat', 'readonly')
        const store = tx?.objectStore('messagesInChat')
        const result = await store?.get(chatID)
        return result || []
    }

    public async addOrUpdateChat(chat: EntitiesChatItem) {
        await this.objectStoreConnect
        const tx = this.db?.transaction('chats', 'readwrite')
        const store = tx?.objectStore('chats')
        const result = await store?.put(chat)
        this.onChangeCallback.forEach((c) => c())
        return result
    }

    public async setChatMessages(
        chatID: string,
        messages: EntitiesMessageItem[]
    ) {
        await this.objectStoreConnect
        const tx = this.db?.transaction('messagesInChat', 'readwrite')
        const store = tx?.objectStore('messagesInChat')
        const result = await store?.put(messages, chatID)
        this.onChangeCallback.forEach((c) => c())
        return result
    }

    public subscribeToChanges(callback: () => void): UnsubscribeFunction {
        this.onChangeCallback.push(callback)
        return () => {
            this.onChangeCallback = this.onChangeCallback.filter(
                (c) => c !== callback
            )
        }
    }
}

export default IndexedDb
