import axios from 'axios'

const { origin, hostname } = window.location

export const wsEndpoint = () => {
    switch (hostname) {
        case 'llmhive.ir':
            return 'wss://api.llmhive.ir/api/v1/ws'
        default:
            return 'wss://api.llmhive.com/api/v1/ws'
    }
}
export const apiHostname = () => {
    switch (hostname) {
        case 'localhost':
        case '127.0.0.1':
        case 'llmhive.com':
            return 'https://api.llmhive.com'
        case 'llmhive.ir':
            return 'https://api.llmhive.ir'
        default:
            return origin
    }
}
export const authHostname = () => {
    switch (hostname) {
        case 'localhost':
        case '127.0.0.1':
        case 'llmhive.com':
            return 'https://llmhive.com'
        case 'llmhive.ir':
            return 'https://llmhive.ir'
        default:
            return origin
    }
}
const instance = axios.create({
    baseURL: apiHostname(),
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        common: {
            Authorization:
                localStorage.getItem('token') === null
                    ? undefined
                    : `Bearer ${localStorage.getItem('token')}`,
        },
    },
})

export const setAuthHeader = (authToken?: string) => {
    instance.defaults.headers.common.Authorization = `Bearer ${authToken}`
}

export const getAuthHeader = () => {
    return instance.defaults.headers.common.Authorization
}

export const isLoggedIn = () => {
    return instance.defaults.headers.common.Authorization
}

export default instance
