/* eslint-disable no-await-in-loop */
/* eslint-disable class-methods-use-this */
import { NavigateFunction } from 'react-router-dom'
import dayjs from 'dayjs'
import {
    EntitiesCallVoice,
    EntitiesRequestMessage,
    EntitiesResponseMessage,
} from '../api/api'
import { wsEndpoint } from '../api/ApiConfig'
import IndexedDb from '../db'
import { IAuthModel } from '../utilities/auth'

class LLMHiveSDK {
    private ws: WebSocket | null = null

    db: IndexedDb = new IndexedDb()

    callHandler: ((event: EntitiesCallVoice) => void) | undefined = undefined

    callModel = ''

    private navigate: NavigateFunction | undefined

    private pushNotification: ((text: string) => void) | undefined

    private sendQueue: EntitiesRequestMessage[] = []

    private eventQueue: EntitiesResponseMessage[] = []

    private isProcessing = false

    connect(
        navigate: NavigateFunction,
        pathname: string,
        pushNotification: (text: string) => void
    ): void {
        this.navigate = navigate
        this.pushNotification = pushNotification

        if (this.ws?.readyState === WebSocket.CONNECTING) {
            return
        }

        console.log('Connecting to WebSocket server')
        const sessionAuth = localStorage.getItem('auth')
        const sessionAuthModel =
            sessionAuth && sessionAuth.length > 0
                ? (JSON.parse(sessionAuth) as IAuthModel)
                : undefined

        this.ws = new WebSocket(wsEndpoint())

        this.ws.onopen = () => {
            console.log('Connected to WebSocket server')
            const authMsg: string = JSON.stringify({
                token: sessionAuthModel?.token,
            })
            this.ws?.send(authMsg)

            this.sendMessage({
                subscribeToChats: {},
            })

            const queue = this.sendQueue
            this.sendQueue = []
            queue.forEach((q) => {
                this.sendMessage(q)
            })
        }

        this.ws.onmessage = async (event) => {
            console.log('<-', event.data)
            this.eventQueue.push(
                JSON.parse(event.data) as EntitiesResponseMessage
            )
            this.processQueue()
        }

        this.ws.onclose = () => {
            console.log('Disconnected from WebSocket server')
            this.ws = null
            setTimeout(() => {
                this.connect(navigate, pathname, pushNotification)
            }, 500)
        }

        this.ws.onerror = (error) => {
            console.error('WebSocket error', error)
            this.ws?.close()
            this.ws = null
            setTimeout(() => {
                this.connect(navigate, pathname, pushNotification)
            }, 500)
        }
    }

    sendMessage(data: EntitiesRequestMessage) {
        if (this.ws?.readyState === WebSocket.OPEN) {
            console.log('->', data)
            this.ws?.send(JSON.stringify(data))
        } else {
            this.sendQueue.push(data)
        }
    }

    private async processQueue(): Promise<void> {
        if (this.isProcessing) {
            return // Exit if already processing
        }

        this.isProcessing = true

        // Loop through the queue and process events sequentially
        while (this.eventQueue.length > 0) {
            const event = this.eventQueue.shift() // Get the first event
            if (event) {
                await this.processMessage(event) // Await the event to finish before processing the next one
            }
        }

        this.isProcessing = false
    }

    async processMessage(data: EntitiesResponseMessage): Promise<void> {
        if (data.chatItem !== undefined) {
            await this.db.addOrUpdateChat(data.chatItem)
        } else if (data.messageItem !== undefined) {
            let chatMsgs = await this.db.listMessagesInChat(
                data.messageItem.chatID || ''
            )

            const exists =
                chatMsgs.filter((m) => m.ID === data.messageItem?.ID).length > 0

            if (!exists) {
                chatMsgs.push(data.messageItem)
            } else {
                chatMsgs = chatMsgs.map((m) =>
                    m.ID === data.messageItem?.ID
                        ? {
                              ID: m.ID,
                              chatID: m.chatID,
                              content: data.messageItem?.content,
                              attachments: data.messageItem?.attachments,
                              role: m.role,
                              createdAt: m.createdAt,
                          }
                        : m
                )
            }

            chatMsgs = chatMsgs.sort((a, b) =>
                dayjs(a.createdAt) < dayjs(b.createdAt) ? -1 : 1
            )

            await this.db.setChatMessages(
                data.messageItem.chatID || '',
                chatMsgs
            )
        } else if (data.messageStreamItem !== undefined) {
            let chatMsgs = await this.db.listMessagesInChat(
                data.messageStreamItem.chatID || ''
            )

            const exists =
                chatMsgs.filter((m) => m.ID === data.messageStreamItem?.ID)
                    .length > 0

            if (!exists) {
                chatMsgs.push(data.messageStreamItem)
            } else {
                chatMsgs = chatMsgs.map((m) =>
                    m.ID === data.messageStreamItem?.ID
                        ? {
                              ID: m.ID,
                              chatID: m.chatID,
                              content: `${m.content || ''}${
                                  data.messageStreamItem?.contentToBeAppended ||
                                  ''
                              }`,
                              role: m.role,
                              createdAt: m.createdAt,
                          }
                        : m
                )
            }

            chatMsgs = chatMsgs.sort((a, b) =>
                dayjs(a.createdAt) < dayjs(b.createdAt) ? -1 : 1
            )

            await this.db.setChatMessages(
                data.messageStreamItem.chatID || '',
                chatMsgs
            )
        } else if (
            data.switchChatItem !== undefined &&
            this.navigate !== undefined
        ) {
            if (this.callHandler !== undefined) {
                this.navigate(
                    `/call/${this.callModel}/${
                        data.switchChatItem.chatID || ''
                    }`
                )
            } else {
                this.navigate(`/c/${data.switchChatItem.chatID || ''}`)
            }
        } else if (data.callVoice) {
            if (this.callHandler !== undefined) {
                this.callHandler(data.callVoice)
            }
        } else if (
            data.error !== undefined &&
            this.pushNotification !== undefined
        ) {
            this.pushNotification(data.error.message || '')
        }
    }
}

export const LLMHive = new LLMHiveSDK()
