import { ArrowPathIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import { useEffect, useState } from 'react'
import { Button, Card, Flex, Text, Title } from '@tremor/react'
import Router from './router'
import Spinner from './components/Spinner'
import { getAuthHeader, setAuthHeader } from './api/ApiConfig'
import { useAuth } from './utilities/auth'
import './App.css'

dayjs.extend(isToday)
dayjs.extend(isYesterday)
dayjs.extend(utc)

export default function App() {
    const {
        isLoading,
        isAuthenticated,
        getAccessTokenSilently,
        getIdTokenClaims,
    } = useAuth()
    const [token, setToken] = useState<string>('')
    const [accessTokenLoading, setAccessTokenLoading] = useState<boolean>(true)
    const [expire, setExpire] = useState<number>(0)
    const [showExpired, setShowExpired] = useState<boolean>(false)

    const checkExpire = () => {
        if (expire !== 0) {
            const diff = expire - dayjs.utc().unix()
            if (diff < 0) {
                setShowExpired(true)
            }
        }
    }

    useEffect(() => {
        const t = setInterval(checkExpire, 5000)
        return () => {
            clearInterval(t)
        }
    }, [expire])

    useEffect(() => {
        if (isAuthenticated && token === '') {
            getIdTokenClaims().then((v) => {
                setExpire(v?.exp || 0)
            })
            getAccessTokenSilently()
                .then((accessToken) => {
                    setAuthHeader(accessToken)
                    setAccessTokenLoading(false)
                    setToken(accessToken)
                })
                .catch((err) => {
                    console.error(err)
                    setAccessTokenLoading(false)
                })
        }
    }, [isAuthenticated])

    return isLoading || accessTokenLoading ? (
        <Flex
            justifyContent="center"
            alignItems="center"
            className="w-screen h-screen dark:bg-gray-900"
        >
            <Spinner />
        </Flex>
    ) : (
        <>
            <Router />
            {showExpired && (
                <Flex
                    flexDirection="col"
                    className="fixed top-0 left-0 w-screen h-screen bg-gray-900/80 z-50"
                >
                    <Card className="w-1/3 mt-56">
                        <Flex
                            flexDirection="col"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Title className="mb-3 text-2xl font-bold">
                                Your session has expired
                            </Title>
                            <Text className="mb-6 text-center">
                                Your session has expired. Please log in again to
                                continue accessing the platform
                            </Text>
                            <Button
                                icon={ArrowPathIcon}
                                onClick={() => {
                                    window.location.href =
                                        window.location.toString()
                                }}
                            >
                                Re-Login
                            </Button>
                        </Flex>
                    </Card>
                </Flex>
            )}
        </>
    )
}
