import { Route, Routes } from 'react-router-dom'
import HomePage from '../page/home'
import { CallbackPage } from '../page/callback'
import LogoutPage from '../page/logout'
import Layout from '../components/Layout'
import TicketListPage from '../page/tickets'
import TicketPage from '../page/tickets/ticket'
import CallAIPage from '../page/call'

const authRoutes = [
    {
        path: '/',
        element: <HomePage />,
    },
    {
        path: '/tickets',
        element: <TicketListPage />,
    },
    {
        path: '/ticket/:id',
        element: <TicketPage />,
    },
    {
        path: '/c/:threadId',
        element: <HomePage />,
    },
    {
        path: '/call/:model/:threadId',
        element: <CallAIPage />,
    },
    {
        path: '/logout',
        element: <LogoutPage />,
    },
    {
        path: '/callback',
        element: <CallbackPage />,
        noAuth: true,
    },
]

export default function Router() {
    return (
        <Layout>
            <Routes>
                {authRoutes.map((route) => (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={route.element}
                    />
                ))}
            </Routes>
        </Layout>
    )
}
