import { Flex } from '@tremor/react'
import { useLocation } from 'react-router-dom'
import Sidebar from './sidebar'
import Notification from './notification'

interface ILayout {
    children: any
}

export default function Layout({ children }: ILayout) {
    const { pathname } = useLocation()
    const shouldShowSidebar = () => {
        if (pathname.includes('/call/')) {
            return false
        }
        return true
    }

    return (
        <Flex
            flexDirection="col"
            justifyContent="start"
            className="w-full h-screen overflow-auto"
        >
            <Flex
                flexDirection="row"
                alignItems="start"
                justifyContent="start"
                className="w-full h-full overflow-auto"
            >
                {shouldShowSidebar() && <Sidebar />}
                <Flex
                    flexDirection="col"
                    justifyContent="start"
                    className="w-full h-full bg-gray-200"
                >
                    {children}
                </Flex>
            </Flex>
            <Notification />
        </Flex>
    )
}
